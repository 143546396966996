.App {
  font-size: 24px;
}

.App-header {
  background-color: rgb(0, 0, 65);
  color: white;
  padding: 50px 15px;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 32px;
}

@media (max-width: 767px) {
  .App-header {
    font-size: 18px;
  }
}

.App-header a,
.App-header span.link {
  font-size: 24px;
  padding: 0 10px;
  cursor: pointer;
  text-decoration: none;
}

.link-wrapper {
  max-width: 100vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.link-wrapper .link {
  padding-top: 35px!important;
}

.App-header a:hover,
.App-header span.link:hover {
  text-decoration: underline;
}

.App-section {
  padding: 50px 15px;
}

.App-footer {
  padding: 25px 15px;
  background-color: black;
  font-size: 16px;
  color: grey;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 700px;
}

.container.centered {
  align-items: center;
}

.App .hero.image {
  height: 95vh;
  /* background-image: url('https://unsplash.com/photos/WStAHvywOZU/download?w=1920'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
}

svg.title-icon {
  width: 150px;
}

.countdown {
  display: flex;
}

.countdown > .number {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  padding: 25px;
}

.countdown > .separator {
  display: flex;
  flex-direction: column;

  padding-top: 25px;
  font-size: 72px;
  line-height: 72px;
  width: 5px;
}

.countdown > .number > .value {
  font-size: 72px;
  line-height: 72px;
}

.countdown > .number > .name {
  font-size: 16px;
}

@media (max-width: 767px) {
  .countdown > .number {
    padding: 10px;
  }

  .countdown > .separator {
    padding-top: 10px;
    font-size: 36px;
    line-height: 36px;
    width: 5px;
  }
  
  .countdown > .number > .value {
    font-size: 36px;
    line-height: 36px;
  }
  
  .countdown > .number > .name {
    font-size: 12px;
  }
}

table {
  border-spacing: 15px;
}

th,
td {
  text-align: left;
  vertical-align: top;
}

button.scroll-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  width: 45px;
  height: 45px;
  background-color: white;
  outline: 0;
  border: none;
  border-radius: 50%;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

button > svg {
  width: 25px;
  height: 25px;
  color: rgb(0, 0, 65);
}

a.button {
  background-color: red;
  color: white;
  padding: 25px;
  text-align: center;
  font-weight: bold!important;
}