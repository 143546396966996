@import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,wght@0,200;0,500;1,200;1,500&family=Roboto:ital,wght@0,200;0,500;1,200;1,500&display=swap');

body {
  margin: 0;
  font-family: 'Fraunces', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 200;
  
  background-color: #eee;
  color: #111;
}

h1, h2, h3, h4, h5, h6, b {
  font-weight: 500;
}

.App .App-section a,
.App .App-section span.link,
.App .App-header a,
.App .App-header span.link {
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 12px!important;
  margin-right: 15px;
}



@media (prefers-color-scheme: dark) {
  body {
      background-color: #111;
      color: #eee;
  }

  a {
      color: #eee!important;
  }
}

.text-center {
  text-align: center;
}

img {
  width: 100%;
  height: auto;
}